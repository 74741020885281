<template>
  <b-card>
    <d-collapse-section
      title="views.playground.index.parameters.categories.title"
      :model="collapse"
      accordion="categories"
      @on:open-or-close="collapse = ! collapse"
    >
      <div slot="body">
        <div class="mt-2 wrapper">
          <b-row>
            <b-col :cols="cols">
              <category-list
                :reload="reload"
                @on:category:edit="editBlockPriceCategory"
                @reset:category-form="resetCategoryModel"
              />
            </b-col>
            <div v-if="cols === '5'" class="split-layout__divider">
              <div class="split-layout__rule"></div>
              <div class="split-layout__rule"></div>
            </div>
            <b-col>
              <form-details
                :key="'form-category' + componentFormKey"
                :category="category"
                @on:category-updated="reload = !reload"
                @reset:category-form="resetCategoryModel"
              />
            </b-col>
          </b-row>
        </div>
      </div>
    </d-collapse-section>
  </b-card>
</template>
<script>
import {getBlockPriceCategory} from "@api/doinsport/services/timetable/blocks/price/category/category.api";
import BlockPriceCategory from "@/classes/doinsport/BlockPriceCategory";

export default {
  data: () => ({
    collapse: false,
    reload: false,
    componentFormKey: 1,
    category: new BlockPriceCategory()
  }),
  components: {
    CategoryList: () => import('./List'),
    FormDetails: () => import('./Details')
  },
  computed: {
    cols() {
      return this.$store.getters["layout/getInnerWidth"] <= 991 ? '12' : '5';
    }
  },
  methods: {
    editBlockPriceCategory(id) {
      getBlockPriceCategory(id)
        .then((response) => {
          this.category = new BlockPriceCategory(response.data);
        })
      ;
    },
    resetCategoryModel() {
      this.componentFormKey += this.componentFormKey;
      this.category = new BlockPriceCategory();
    }
  }
}
</script>
<style lang="scss" scoped>
@import "@lazy/_b-card.scss";

.card .card-body {
  padding-left: 30px;
}
</style>
