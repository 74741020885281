import { hydrate } from "@/utils/form";

export default class BlockPriceCategory {
  id = null;
  name = null;
  photo = null;
  activityType = 'sport';
  club = JSON.parse(localStorage.getItem('selectedClub'));

  constructor(object = null) {
    if (object) {
      this.serialize(object);
    }
  }

  serialize (object) {
    hydrate(this, object);
  }
}
